@import './variables';

.user_title {
    display: flex;
    align-items: center;
}

.pg_user {
    background-color: $secondary;
}

.joined_on {
    vertical-align: middle;
    margin-left: 5px;
    margin-top: 6px;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace !important;
}

.fa-envelope {
    margin-right: 8px;
}
