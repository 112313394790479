/*
    Global SCSS Styles
*/

@import './variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    background-color: #3793fc;
    color: white;
}

* {
    font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    background-color: $main-dark;
    position: relative;
    padding-bottom: 4em;
    min-height: 100%;
    color: $text;
}

.active {
    display: block;
}

button {
    outline: none !important;
}

h1 {
    font-weight: bold !important;
}