@import './variables';

.main-nav {
    list-style-type: none;
}

.nav-links {
    text-decoration: none;
    color: white;
    padding: 100%;
    display: block;
}

.main-nav li {
    text-align: left;
}

.navbar-toggle {
    cursor: pointer;
    color: white;
    font-size: 24px;
}

.active {
    display: block;
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    align-items: center;
}

.main-nav li {
    margin: 0;
}

.logo {
    margin-top: 0;
}

.logo:hover,
.nav-links:hover {
    color: rgba(255, 255, 255, 1);
}
