.create-user-container {
    padding: clamp(15px, 5vw, 100px);
    width: 100%;
}

.create-user-heading {
    font-size: 2em;
    font-weight: 700;
}

.input-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.input {
    padding: 10px 0;
}

.input p {
    font-size: 1.2em;
}

.input input {
    font-size: 1.2em;
    padding: 10px;
    color: black;
    border: 1px #ccc solid;
    width: 100%;
}

#create-user-button {
    padding: 20px 40px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1.1em;
}
